import React from 'react';
import loadable from '@loadable/component'

import Layout from '../components/Layout';
import Header from '../components/Header';

import Footer from '../components/Footer';

import screenbc from '../assets/images/screen-BoomCars.png';
import logobc from '../assets/images/boomcars-icon.png';

import screentop7 from '../assets/images/screen-top-7-1.png';
import logotop7 from '../assets/images/top-7-logo.png';
import screenconnectit from '../assets/images/screen-ConnectIt.png';
import logoconnectit from '../assets/images/connectit-icon.png';
import screenjinglequizz from '../assets/images/screen-JingleQuizz.png';
import logojinglequizz from '../assets/images/jinglequizz-icon.png';
import appstore from '../assets/images/appstore-bk.png';
import googleplay from '../assets/images/google-play-bk.png';

import ContactForm from "../components/ContactForm"

const SimpleCarousel = loadable(() => import('simple-react-carousel'))

const IndexPage = () => {

  const stylesTitle = {
    position: 'absolute ',
    bottom: '65%',
    left: '10%',
    maxWidth: '450px'
  };

  const stylesButton = {
    bottom: '20em',
    right: '-10em',
    maxWidth: '450px',
    position: 'relative '
  };

  return (<Layout>
    <Header />
    <section className="home" id="home" >
      <section className="games" id="games" >
        <div className="transition-top-games"></div>
        {/* GAME 1 */}
        <SimpleCarousel autoPlay={true} autoChangeTime={10}>
        <div className="container header-content">
          <div className="row pt-5">
            <div className="col d-flex align-items-center justify-content-center">
              <div className="row">
                <div className="d-none d-lg-block">
                <div className="col d-flex flex-column align-items-center justify-content-center">
                  <img className="img-fluid" src={screenbc} className="screen-top7" />
                </div>
                </div>
                <div className="col d-flex flex-column">
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <img src={logobc} className="bc-logo" />
                    <p className="our-first-hit">CASUAL GAMING</p>
                    <p className="our-first-hit-text">In Boom Cars the rules are simple: be the last man standing! </p>
                    <p className="our-first-hit-text">Choose your car, enter the arena, then cause the most damage possible with crazy crashes and bonus weapons. Accelerate, reverse, drift, jump - anything goes to come in first!</p>                    
                  </div>
                  <div className="d-flex flex-row align-items-center justify-content-center">
                    <a target="_blank" href="https://apps.apple.com/fr/app/boomcars/id1585127252"><img src={appstore} className="storelogo px-2" /></a>
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=games.elia.boomcars" ><img src={googleplay} className="storelogo" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container header-content">
          <div className="row mt-5 pt-5">
            <div className="col d-flex align-items-center justify-content-center">
              <div className="row">
                <div className="d-none d-lg-block">
                <div className="col d-flex flex-column align-items-center justify-content-center">
                  <img className="img-fluid" src={screentop7} className="screen-top7" />
                </div>
                </div>
                <div className="col d-flex flex-column">
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <img src={logotop7} className="top-7-logo" />
                    <p className="our-first-hit">TRIVIA</p>
                    <p className="our-first-hit-text">Discover Top 7, the family word game. Find the top answers related to the topic.
                    Example: "Something that flies", what's come to mind? Bird, plane, bee...
Ok, but will you find the Top 7? Easy? Try it now!</p>
                  </div>
                  <div className="d-flex flex-row align-items-center justify-content-center">
                    <a target="_blank" href="https://apps.apple.com/fr/app/top-7/id1499972852"><img src={appstore} className="storelogo px-2" /></a>
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=games.elia.word7" ><img src={googleplay} className="storelogo" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* GAME 2 */}
        <div className="container header-content">
          <div className="row mt-5 pt-5">
            <div className="col d-flex align-items-center justify-content-center">
              <div className="row">
                <div className="d-none d-lg-block">
                <div className="col d-flex flex-column align-items-center justify-content-center">
                  <img className="img-fluid" src={screenconnectit} className="screen-top7" />
                </div>
                </div>
                <div className="col d-flex flex-column">
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <img src={logoconnectit} className="top-7-logo" />
                    <p className="our-first-hit">CONNECT IT</p>
                    <p className="our-first-hit-text">In Connect It, match the pairs of pictures to make all the lights go green! It's up to you to figure out the link between pictures and match them to move on to the next level. To match the pictures, just plug a picture's cord into the right socket. It's harder than you'd think!</p>
                  </div>
                  <div className="d-flex flex-row align-items-center justify-content-center">
                    <a target="_blank" href="https://apps.apple.com/fr/app/connect-it-images-quiz/id1547986453"><img src={appstore} className="storelogo px-2" /></a>
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=games.elia.connectit" ><img src={googleplay} className="storelogo" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* GAME 3? */}
        <div className="container header-content">
          <div className="row mt-5 pt-5">
            <div className="col d-flex align-items-center justify-content-center">
              <div className="row">
                <div className="d-none d-lg-block">
                <div className="col d-flex flex-column align-items-center justify-content-center">
                  <img className="img-fluid" src={screenjinglequizz} className="screen-top7" />
                </div>
                </div>
                <div className="col d-flex flex-column">
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <img src={logojinglequizz} className="top-7-logo" />
                    <p className="our-first-hit">JINGLE QUIZZ</p>
                    <p className="our-first-hit-text">In Jingle Quiz, test your knowledge of advertising jingles and theme songs that you hear every day on the radio and TV. Can you recognize the world's biggest brand sounds? The concept is simple, listen to a short clip of a jingle and find the answer using the given letters. It's harder than you think!</p>
                  </div>
                  <div className="d-flex flex-row align-items-center justify-content-center">
                    <a target="_blank" href="https://apps.apple.com/fr/app/jingle-quiz/id1552362023"><img src={appstore} className="storelogo px-2" /></a>
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=games.elia.logosound" ><img src={googleplay} className="storelogo" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </SimpleCarousel>
      </section>
      <section id="about" className="about-section">
        <div className="transition-games-about"></div>
        <div data-sal="fade" data-sal-duration="500" className="container section-container about-container">
          <div className="row mt-5">
            <div className="col-md-6">
              <div className="row">
                <div className="col d-flex flex-column">
                  <p className="section-title mt-5">ABOUT US</p>
                  <p className="section-content pb-5">ELIA Games is an ambitious Mobile Gaming Studio & publisher that aims to create a world leader.
                  In a mobile gaming market that has been constantly exploding in recent years, ELIA GAMES relies on the unique expertise of its team (EX SCIMOB, the developer of the 94 trilogy), to develop a leading global player.
With the release in March 2020 of its first Top 7 game, ELIA GAMES is initially tackling the market of Trivia and Word games... what's next?</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section data-sal="fade" data-sal-duration="500" id="join" className="join-section">
        <div className="div-drop">
          <div class="drop-container">
          <div class="drop"></div>
          </div>
        </div>
        <div data-sal="fade" data-sal-duration="500" className="container section-container">
            <div className="row">
              <div className="col">
                <div className="row mt-5 pt-5">
                  <div className="col d-flex flex-column">
                    <p className="section-title-black">CONTACT US</p>
                   
                        <ContactForm></ContactForm>                    
                  </div>
                </div>
              </div>
            </div>
          </div>
      </section>
      <div className="footer-top-transition"></div>
    </section>

    <Footer />
  </Layout>);


};

export default IndexPage;
