import React from 'react';


export default function Button({children, href, bgcolor1, bgcolor2, color, ...props}) {

  const text = children  
  
  bgcolor2 = bgcolor2?bgcolor2:bgcolor1
  color = color?color:"#fff"
  
  
  const styleButton = {
    backgroundImage: `linear-gradient(to right, ${bgcolor1}, ${bgcolor2})`,
    color:color,
    marginTop: `15px`    
  }

  return (
    <a  className="button" style={styleButton} target={props.blank?"_blank":"_self"} href={href} {...props}> {text} </a>
  );
}
